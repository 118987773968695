export const menuItems = {

  // header menu items
  header: [
    {
      label: "Home",
      to: "/",
      dropdown: false,
    },
    // {
    //   label: "Accommodation",
    //   to: "/accommodation",
    //   dropdown: false,
    // },
    {
      label: "History",
      to: "/history",
      dropdown: false,
    },
    {
      label: "Registration",
      to: "",
      dropdown: true,
      items: [
        { label: "Registration", to: "/registration" },
        { label: "Accommodation", to: "/accommodation" },
      ],
    },
    {
      label: "Program",
      to: "",
      dropdown: true,
      items: [
        { label: "Program", to: "/program" },
        { label: "Speakers", to: "/speakers" },
      ],
    },
    {
      label: "Destination",
      to: "",
      dropdown: true,
      items: [
        { label: "Venue", to: "/venue" },
        { label: "Visit Brisbane", to: "/visit-brisbane" },
      ],
    },
    {
      label: "Sponsorship",
      to: "/sponsorship",
      dropdown: false,
      items: [
        { label: "Sponsorship", to: "/sponsorship" },
        // { label: "Sponsors & Exhibitors", to: "/sponsors-and-exhibitors" },
      ],
    },
    {
      label: "Contact",
      to: "/contact",
      dropdown: false,
    },
  ],

  // footer menu items
  footer: [
    {
      label: "About",
      items: [
        { label: "Our Story", to: "#" },
        { label: "Our Team", to: "#" },
        { label: "Careers", to: "#" },
      ],
    },
    {
      label: "Program",
      items: [
        { label: "Layouts", to: "#" },
        { label: "Templates", to: "#" },
        { label: "Themes", to: "#" },
      ],
    },
    {
      label: "Destination",
      items: [
        { label: "Documentation", to: "#" },
        { label: "Blog", to: "#" },
        { label: "Community", to: "#" },
      ],
    },
    {
      label: "Contact",
      items: [
        { label: "Support", to: "#" },
        { label: "Sales", to: "#" },
        { label: "Partnerships", to: "#" },
      ],
    },
  ],

  // social menu items
  social: [
    {
      name: "facebook",
      url: "#",
      screenReader: "Follow us on Facebook",
    },
    {
      name: "linkedin",
      url: "#",
      screenReader: "Follow us on LinkedIn",
    },
    {
      name: "instagram",
      url: "#",
      screenReader: "Follow us on Instagram",
    },
    {
      name: "twitter",
      url: "#",
      screenReader: "Follow us on Twitter",
    },
  ]
};
