import React from "react";
import { useLocation } from '@reach/router';
import { Link } from "gatsby";
import { menuItems } from "@/data/menuItems";
import UserForm from '@/components/form/EOI-Form';
import { Facebook, Linkedin, Instagram, Twitter } from 'lucide-react';
import { KeyDates } from "@/components/KeyDates";
import { StaticImage } from "gatsby-plugin-image";
import config from '@/utils/config';

const iconMap = {
  facebook: <Facebook strokeWidth={1} size={30} />,
  linkedin: <Linkedin strokeWidth={1} size={30} />,
  instagram: <Instagram strokeWidth={1} size={30} />,
  twitter: <Twitter strokeWidth={1} size={30} />,
};

const FooterLinkSection = ({ label, items }) => (
  <div className="space-y-4">
    <h4 className="text-lg font-bold mt-0 text-white">{label}</h4>
    <ul className="space-y-2 text-white/80">
      {items.map((link, index) => (
        <li key={index}>
          <Link to={link.to}>{link.label}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default function Footer() {
  const eventName = 'ANZSPD 2025';
  const location = useLocation();
  const currentPath = location.pathname;

  const normalizePath = (path) => (path.endsWith('/') ? path : `${path}/`);
  const normalizedCurrentPath = normalizePath(currentPath);
  const normalizedCustomPages = config.sidebar.customPages.map(normalizePath);

  const isSidebarActiveForPage =
    config.sidebar.enabled &&
    normalizedCustomPages.includes(normalizedCurrentPath);

  const shouldRenderFooterSection = !isSidebarActiveForPage;

  return (
    <footer>
      {shouldRenderFooterSection && (
      <section className="relative bg-slate-300">
        <div className="container px-4 md:px-6 py-12 md:py-16">
          <h3 className="text-center mt-0 mb-10 text-black/70">Key Dates</h3>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <KeyDates />
          </div>
        </div>
        <StaticImage
          src="../images/tooth.png"
          alt="Tooth art"
          width={200}
          layouts="constrained"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          className="!absolute bottom-[-60px] right-10 lg:right-20 opacity-10 2xl:opacity-100"
        />
      </section>
      )}

      <section className="bg-c1">
        <div className="container px-4 md:px-6 py-12 md:py-16">
          <div className="flex items-center justify-center w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center w-full rounded-lg">
              <div className="pb-10 lg:pb-0 lg:pr-8 border-b lg:border-b-0 border-r-0 lg:border-r border-white/50 dark-theme">
                <h3 className="mb-4 text-c5">Express your interest today!</h3>
                <UserForm eventName={eventName} />
              </div>
              <div className="lg:pl-8 pt-8 lg:pt-0 flex justify-center items-center">
                <Link to="/">
                  <div className="masked-img w-[350px] h-[350px] lg:w-[500px] lg:h-[500px] mask-size-350 sm:mask-size-400 lg:mask-size-500"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* <section className="bg-c1/80 border-y border-white/50">
        <div className="container mx-auto px-4 md:px-6 py-12 grid grid-cols-2 lg:grid-cols-4 gap-8 text-white">
          {menuItems.footer.map((section, index) => (
            <FooterLinkSection key={index} label={section.label} items={section.items} />
          ))}
        </div>
      </section> */}

      <section className="bg-c5">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <div className="flex flex-col lg:flex-row justify-center gap-3 py-6">
            <div className="order-2 lg:order-1 text-white">© 2024 <a href="https://icmsaust.com.au/" target="_blank" rel="noopener noreferrer" className="text-dteal">ICMSA</a>. All rights reserved. | <a href="https://icmsaust.com.au/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text-dteal">Privacy Policy</a></div>
            {/* <div className="flex flex-row gap-3 order-1 lg:order-2 justify-center items-end">
              {menuItems.social.map((social, index) => (
                <a
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="text-white hover:text-dteal flex items-center"
                  aria-label={social.screenReader}
                >
                  {iconMap[social.name.toLowerCase()]}
                  <span className="sr-only">{social.screenReader}</span>
                </a>
              ))}
            </div> */}
          </div>
        </div>
      </section>
    </footer>
  );
}
